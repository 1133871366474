<template>
  <div id="wrapper">
    <div class="loader" v-if="loading"></div>
    <main class="content" v-show="!loading">
      <div class="container">
        <div class="search__fields search__fields--innerPage">
          <div class="row">
            <div class="col-xl-4 col-md-6">
              <div class="fieldFloating">
                <autocomplete
                  :location-data="location"
                  @getLocation="getLocation"
                  :isFromLanding="false"
                  :isReadonly="false"
                  fromFront="1"
                />
              </div>
            </div>
            <div class="col-md-6 col-xl-4" v-click-outside="hideSlider">
              <div class="row">
                <div class="col-md-12">
                  <div class="search__field fieldFloating">
                    <input
                      type="text"
                      max="500"
                      min="1"
                      placeholder=" "
                      :value="headCount"
                      class="formControl validate_number"
                      @click="isSlider = true"
                    />

                    <label class="fieldFloating__label">Head counts</label>
                  </div>
                </div>

                <!-- <div class="col-md-6">
                                    <div class="search__field fieldFloating">
                                        <input
                                            type="number"
                                            max="500"
                                            min="1"
                                            placeholder=" "
                                            v-model="max_capacity"
                                            class="formControl validate_number"
                                            @click="isMaxSlider = true"
                                        />
                                        <label class="fieldFloating__label"
                                            >Max Head counts</label
                                        >
                                    </div>
                                </div> -->
              </div>
              <div class="search__field fieldFloating minmaxslider">
                <!-- <VueSimpleRangeSlider
                                    v-if="isSlider"
                                    :min="0"
                                    :max="500"
                                    v-model="range"
                                /> -->
                <vue-slider
                  v-if="isSlider"
                  v-model="headCount"
                  v-bind="options"
                  :max="500"
                  :min="0"
                  class="formControl"
                ></vue-slider>
                <!-- <vue-slider
                                    v-if="isMaxSlider"
                                    v-model="max_capacity"
                                    v-bind="options"
                                    :max="max"
                                    :min="min"
                                    class="formControl float-right"
                                ></vue-slider> -->
              </div>
            </div>
            <div class="col-xl-4 col-md-12">
              <div class="fieldFloating">
                <div class="d-flex">
                  <datepicker
                    ref="picker"
                    @focusout.native="close"
                    class="formControl"
                    v-model="start_date"
                    @focusin.native="getStartDate"
                    :disabledDates="disabledDates"
                  >
                  </datepicker>

                  <button
                    v-if="start_date"
                    class="date-reset"
                    @click="removeDate()"
                  >
                    X
                  </button>
                </div>
                <label
                  :class="
                    start_date
                      ? 'fieldFloating__label date_selected'
                      : 'fieldFloating__label date_open'
                  "
                  >Start Date</label
                >
              </div>
            </div>
            <!-- <div
                            class="col-xl-2"
                            v-click-outside="hideContractLength"
                        >
                            <div class="fieldFloating">
                                <input
                                    type="text"
                                    placeholder=" "
                                    v-model="contractLength"
                                    class="formControl"
                                    readonly
                                />

                                <label class="fieldFloating__label"
                                    >Contract Length</label
                                >
                                <ul
                                    class="autocomplete-results"
                                    style
                                    v-if="isContractLength"
                                >
                                    <li
                                        v-for="(contLength,
                                        index) in contractLengths"
                                        :key="index"
                                        class="autocomplete-result"
                                        @click="
                                            contractLength =
                                                contLength['length'];
                                            isContractLength = false;
                                        "
                                    >
                                        {{ contLength["length"] | getLength }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2">
                            <div class="fieldFloating">
                                <input
                                    type="text"
                                    placeholder=" "
                                    v-model="layoutDesign"
                                    class="formControl"
                                    readonly
                                />
                                <label class="fieldFloating__label"
                                    >Layout Design</label
                                >
                                <ul
                                    class="autocomplete-results"
                                    style
                                    v-if="isLayoutDesign"
                                >
                                    <li
                                        class="autocomplete-result"
                                        @click="setLayout('Basic')"
                                    >
                                        Basic
                                    </li>
                                    <li
                                        class="autocomplete-result"
                                        @click="setLayout('Standard')"
                                    >
                                        Standard
                                    </li>
                                    <li
                                        class="autocomplete-result"
                                        @click="setLayout('Premium')"
                                    >
                                        Premium
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-2">
                            <div class="fieldFloating">
                                <input
                                    type="text"
                                    placeholder=" "
                                    class="formControl"
                                />
                                <label class="fieldFloating__label"
                                    >More Filters</label
                                >
                            </div>
                        </div> -->
          </div>
        </div>
        <div class="row result" v-if="searchData">
          <div class="result__colLeft col-xl-7 order-1 order-xl-0">
            <div class="result__grid">
              <!-- <div class="result__filter">
                <div
                  @click="getNearBy"
                  class="d-flex align-items-center result__filter__header js-filterToggle"
                >
                  <i class="fas fa-map-marker-alt"></i>3 nearby locations with
                  similar listings
                </div>
                <div class="result__filter__body">
                  <div class="d-flex result__filter__item is-filterSelect">
                    <div class="result__filter__title">Seattle</div>
                    <div class="result__filter__bar">
                      <div class="eProgress">
                        <div
                          class="eProgress__handle"
                          style="left: 55%; width: 233px;"
                        >
                          <span>$1,000</span>
                          <span>$1,500</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex result__filter__item">
                    <div class="result__filter__title">Bellevue</div>
                    <div class="result__filter__bar">
                      <div class="eProgress">
                        <div
                          class="eProgress__handle"
                          style="left: 35%; width: 233px;"
                        >
                          <span>$800</span>
                          <span>$1,200</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex result__filter__item">
                    <div class="result__filter__title">Redmond</div>
                    <div class="result__filter__bar">
                      <div class="eProgress">
                        <div
                          class="eProgress__handle"
                          style="left: 25%; width: 233px;"
                        >
                          <span>$500</span>
                          <span>$1,100</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex result__filter__item">
                    <div class="result__filter__title">Issaquah</div>
                    <div class="result__filter__bar">
                      <div class="eProgress">
                        <div
                          class="eProgress__handle"
                          style="left: 5%; width: 203px;"
                        >
                          <span>$300</span>
                          <span>$700</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
              <div
                class="row"
                v-for="(item, index) in chunkProperties"
                :key="index"
              >
                <div
                  class="col-md-6 clickCursor"
                  @click.prevent="getDetail(item[0]['id'])"
                >
                  <a :href="rightClickLink(item[0]['id'])">
                    <div class="result__item">
                      <div class="result__thumb">
                        <!-- <button class="btn btn--streetView">
                          <i class="fas fa-street-view"></i>
                        </button> -->

                        <img
                          :src="`${baseAPI}${item[0].thumbnail_image}`"
                          alt="Property"
                        />
                      </div>
                      <div class="result__content">
                        <h3 class="">
                          <div class="property-title">
                            {{ item[0]["title"] || "N/A" }}
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="max-headcount">
                              Max Head Counts
                              <!-- {{ item[0].capacity }} -->
                              <div class="noofmaxheadcount">
                                <i class="fas fa-users"></i>
                                {{
                                  getHeadCount(
                                    item[0].capacity,
                                    item[0].layoutDesigns
                                  )
                                }}
                              </div>
                            </div>
                            <div class="textRight t-right">
                              <div class="contracts">
                                {{
                                  getContract(item[0].contractLengths)
                                    | getLength
                                }}
                                contract
                              </div>
                              <div class="layout">Basic Layout</div>
                            </div>
                          </div>
                        </h3>
                        <div class="d-flex justify-content-between price_date">
                          <div class="">
                            <div class="price">
                              <!-- ${{ item[0]["price"] }} -->
                              <div class="exactprice">
                                ${{
                                  getTotalMonthlyCost(
                                    item[0].price,
                                    item[0].layoutDesigns,
                                    item[0].contractLengths
                                  )
                                }}
                              </div>
                            </div>
                            <div class="plan_paid">monthly/person</div>
                          </div>
                          <div class="textRight t-right">
                            <div
                              class="contracts"
                              v-if="available(item[0].available_from)"
                            >
                              <p class="mb0 Boldtext">Available now</p>
                            </div>
                            <div class="contracts" v-else>
                              <p class="mb0">Available from</p>
                              <p class="mb0 date">
                                {{ item[0].available_from | getFormatDate }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div
                  class="col-md-6 clickCursor"
                  v-if="item[1]"
                  @click="getDetail(item[1]['id'])"
                >
                  <a :href="rightClickLink(item[1]['id'])">
                    <div class="result__item">
                      <div class="result__thumb">
                        <!-- <button class="btn btn--streetView">
                          <i class="fas fa-street-view"></i>
                        </button> -->
                        <img
                          :src="`${baseAPI}${item[1].thumbnail_image}`"
                          alt="Property"
                        />
                      </div>
                      <div class="result__content">
                        <h3 class="">
                          <div class="property-title">
                            {{ item[1].title || "N/A" }}
                          </div>
                          <div class="d-flex justify-content-between">
                            <div class="max-headcount">
                              Max Head Counts
                              <!-- {{ item[0].capacity }} -->
                              <div class="noofmaxheadcount">
                                <i class="fas fa-users"></i>
                                {{
                                  getHeadCount(
                                    item[1].capacity,
                                    item[1].layoutDesigns
                                  )
                                }}
                              </div>
                            </div>
                            <div class="textRight t-right">
                              <div class="contracts">
                                {{
                                  getContract(item[1].contractLengths)
                                    | getLength
                                }}
                                contract
                              </div>
                              <div class="layout">Basic Layout</div>
                            </div>
                          </div>
                        </h3>
                        <div class="d-flex justify-content-between price_date">
                          <div class="">
                            <div class="price">
                              <!-- ${{ item[0]["price"] }} -->
                              <div class="exactprice">
                                ${{
                                  getTotalMonthlyCost(
                                    item[1].price,
                                    item[1].layoutDesigns,
                                    item[1].contractLengths
                                  )
                                }}
                              </div>
                            </div>
                            <div class="plan_paid">monthly/person</div>
                          </div>
                          <div class="textRight t-right">
                            <div
                              class="contracts"
                              v-if="available(item[1].available_from)"
                            >
                              <p class="mb0 Boldtext">Available now</p>
                            </div>
                            <div class="contracts" v-else>
                              <p class="mb0">Available from</p>
                              <p class="mb0 date">
                                {{ item[1].available_from | getFormatDate }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="result__colRight col-xl-5">
            <div class="map">
              <google-map
                :markers="properties"
                styleData="width:100%;  height: 100vh;"
              ></google-map>
            </div>
          </div>
        </div>
        <div class="note" v-if="!searchData && !loading">
          <h2 class="note__text">
            We currently don’t have any spaces that fit the criteria you are
            looking for.
          </h2>
          <h3 class="note__help">Get alerted when we find a match.</h3>
        </div>
        <div class="alertFilter" v-if="!searchData && !loading">
          <!-- <div class="alertFilter__item" @click="getSearchByLocation()">
            <i class="fas fa-map-marker-alt"></i>
            <div>
              9 listings in Mercer Island, WA
              <span class="colorGray--darker">(7 miles from Seattle)</span>
            </div>
          </div>-->
          <div
            class="alertFilter__item"
            @click="getSearchByDate()"
            v-if="getDateRange()"
          >
            <div>
              <div v-if="this.start_date">
                <i class="fas fa-calendar-alt"></i>
                {{ dateProperties }} listings available in
                {{ getDateRange() }}
              </div>
              <div v-else>
                <i class="fas fa-map-marker-alt"></i>
                No property available in your searched location
              </div>
            </div>
          </div>
          <div class="alertFilter__item" @click="getSearchByCapacity()">
            <i class="fas fa-user-alt"></i>
            <div>
              {{ capacityProperties }} listings that accomodate
              {{ capacityRange }} people
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getPropertyBySearch } from "@/api/properties";
import { getContractLengths } from "@/api/contractLengths";
import Datepicker from "vuejs-datepicker";
import autocomplete from "./AutoComplete";
import GoogleMap from "./GoogleMap";
import VueSlider from "vue-slider-component";
import { getToken } from "@/utils/auth";
import moment from "moment";

import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";

import VueCryptojs from "vue-cryptojs";

export default {
  name: "ListProperties",
  components: {
    Datepicker,
    autocomplete,
    VueSlider,
    GoogleMap,
    VueSimpleRangeSlider,
  },
  data() {
    const min = this.$router.history.current.query.min_capacity
      ? this.$router.history.current.query.min_capacity
      : null;
    const max = this.$router.history.current.query.max_capacity
      ? this.$router.history.current.query.max_capacity
      : null;
    const headCount = this.$router.history.current.query.headCount
      ? this.$router.history.current.query.headCount
      : null;
    return {
      baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_IMAGE_PATH,
      isSlider: false,
      headCount,
      isMaxSlider: false,
      min,
      max,
      range: [min, max],
      isContractLength: false,
      isLayoutDesign: false,
      contractLength: null,
      searchData: false,
      contractLengths: [],
      layoutDesign: null,
      markers: [],
      places: [],
      currentPlace: null,
      properties: [],
      chunkProperties: [],
      perPage: "",
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      map: null,
      infoContent: "",
      loading: true,
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      options: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 100,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "focus",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      capacityProperties: 0,
      dateProperties: 0,
      dateRange: null,
      capacityRange: null,
      start_date: this.$router.history.current.query.start_date
        ? new Date(this.$router.history.current.query.start_date)
        : null,
      location: this.$router.history.current.query.location
        ? this.$router.history.current.query.location
        : null,
      capacity: this.$router.history.current.query.capacity
        ? this.$router.history.current.query.capacity
        : null,
      min_capacity: this.$router.history.current.query.min_capacity
        ? this.$router.history.current.query.min_capacity
        : null,
      max_capacity: this.$router.history.current.query.max_capacity
        ? this.$router.history.current.query.max_capacity
        : null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      // rightClickLink: '/detail/' + btoa(item[0]['id'])
    };
  },
  watch: {
    headCount: {
      immediate: true,
      handler(val) {
        let count = parseInt(val, 10);
        let min_capacity =
          count - (count * 20) / 100 - 5 < 0
            ? 0
            : parseInt(count - (count * 20) / 100 - 5, 10);
        let max_capacity = parseInt(count + (count * 20) / 100 + 5, 10);
        let searchData = {
          location: this.location,
          min_capacity:
            min_capacity.toString() === "NaN" ? null : min_capacity.toString(),
          max_capacity:
            max_capacity.toString() === "NaN" ? null : max_capacity.toString(),
          start_date: this.start_date,
        };
        this.getPropertyBySearchCriteria(searchData);
        this.$router.push({
          path: "list",
          query: {
            location: this.location,
            // capacity: params,
            min_capacity: min_capacity,
            max_capacity: max_capacity,
            start_date: this.start_date,
            headCount: this.headCount,
          },
        });
      },
    },
    // capacity: function(params) {
    //     let searchData = {
    //         location: this.location,
    //         capacity: params,
    //         start_date: this.start_date,
    //     };
    //     this.getPropertyBySearchCriteria(searchData);
    //     this.$router.push({
    //         path: "list",
    //         query: {
    //             location: this.location,
    //             capacity: params,
    //             start_date: this.start_date,
    //         },
    //     });
    // },
    // range: function(val) {
    //     let searchData = {
    //         location: this.location,
    //         min_capacity: parseInt(val[0], 10),
    //         max_capacity: parseInt(val[1], 10),
    //         start_date: this.start_date,
    //     };
    //     this.getPropertyBySearchCriteria(searchData);
    //     this.$router.push({
    //         path: "list",
    //         query: {
    //             location: this.location,
    //             // capacity: params,
    //             min_capacity: parseInt(val[0], 10),
    //             max_capacity: parseInt(val[1], 10),
    //             start_date: this.start_date,
    //         },
    //     });
    // },
    start_date: function (params) {
      let startDate;
      if (params) {
        let year = params.getFullYear();
        let date = params.getDate();
        let month = params.getMonth() + 1;

        startDate = year + "-" + month + "-" + date;
      } else {
        startDate = null;
      }
      let searchData = {
        location: this.location,
        // capacity: this.capacity,
        min_capacity: this.min_capacity,
        max_capacity: this.max_capacity,
        start_date: startDate,
      };

      this.getPropertyBySearchCriteria(searchData);
      this.$router.push({
        path: "list",
        query: {
          location: this.location,
          // capacity: this.capacity,
          min_capacity: this.min_capacity,
          max_capacity: this.max_capacity,
          start_date: startDate,
          headCount: this.headCount,
        },
      });
    },
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  created() {
    getContractLengths(1, "all").then((res) => {
      this.contractLengths = res.data.data;
    });
    let searchData = {
      location: this.location,
      // capacity: this.capacity,
      min_capacity: this.min_capacity,
      max_capacity: this.max_capacity,
      // min_capacity: this.
      // max_capacity: this.
      start_date: this.start_date,
    };
    if (!this.location && !this.capacity && !this.start_date) {
      this.$notify({
        group: "notify",
        type: "warning",
        text: "please add appropriate search data",
      });
      this.$router.push("/");
    }
    this.searchData = false;
    this.loading = true;
    this.getPropertyBySearchCriteria(searchData);
  },
  mounted() {
    var testarray = document.getElementsByClassName("vdp-datepicker");
    if (this.start_date) {
      testarray[0].classList.add("is-open-date");
    } else {
      testarray[0].classList.remove("is-open-date");
    }
  },
  methods: {
    rightClickLink(id) {
      return "/detail/" + btoa(id);
    },
    getTotalMonthlyCost(oldPrice, layouts, contracts) {
      if (oldPrice) {
        return oldPrice.toFixed(2);
      }
      let defaultLayout =
        layouts.filter((x) => x.is_default).length > 0
          ? layouts.filter((x) => x.is_default)[0]
          : layouts[0];

      let defaultContract =
        contracts.filter((x) => x.is_default).length > 0
          ? contracts.filter((x) => x.is_default)[0]
          : contracts[0];
      return (
        (defaultLayout.price * defaultContract.percent) /
        100 /
        defaultLayout.capacity
      ).toFixed(2);
    },
    getHeadCount(oldCount, layouts) {
      if (oldCount) {
        return oldCount;
      }
      if (layouts.filter((x) => x.is_default).length > 0) {
        return layouts.filter((x) => x.is_default)[0].capacity;
      } else {
        return this.getMaxHeadCount(layouts.filter((x) => !x.is_default));
      }
      // let defaultLayout =
      //   layouts.filter((x) => x.is_default).length > 0
      //     ? layouts.filter((x) => x.is_default)[0]
      //     : this.getMaxHeadCount(layouts.filter((x) => !x.is_default));
      // let defaultLayout =
      //   layouts.filter((x) => x.is_default).length > 0
      //     ? layouts.filter((x) => x.is_default)[0]
      //     : layouts[0];
      // return defaultLayout.capacity;
    },
    getMaxHeadCount(layouts) {
      let capacities = [];
      layouts.forEach((layout) => {
        capacities.push(layout.capacity);
      });
      return Math.max(...capacities);
    },
    getContract(contracts) {
      console.log(contracts);
      let contra = contracts.filter((x) => x.is_default);
      if (contra.length > 0) {
        return contra[0].contract_length?.length;
      } else {
        console.log(contracts[0]);
        return contracts[0].contract_length?.length;
      }
    },
    removeDate() {
      this.start_date = "";
      this.$router.push({
        path: "list",
        query: {
          location: this.location,
          // capacity: this.capacity,
          min_capacity: this.min_capacity,
          max_capacity: this.max_capacity,
          headCount: this.headCount,
        },
      });
    },
    removeClasss() {
      var body = document.body;
      body.classList.remove("is-modalOpen");
    },
    getDateRange() {
      let date = new Date(this.start_date);
      let year = date.getFullYear();
      let date1 = date.getDate();
      let month = date.getMonth();
      if (month < 0 || !year) {
        return false;
      }
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month] + " " + year;
    },
    hideSlider() {
      this.isSlider = false;
      this.isMaxSlider = false;
    },
    hideContractLength() {
      this.isContractLength = false;
    },
    getLocation(location, isSearchChange = false) {
      this.location = location;
      if (isSearchChange == true) {
        // this.$route.query.location = this.location;
        let count = parseInt(this.headCount, 10);
        let min_capacity =
          count - (count * 20) / 100 - 5 < 0
            ? 0
            : parseInt(count - (count * 20) / 100 - 5, 10);
        let max_capacity = parseInt(count + (count * 20) / 100 + 5, 10);
        this.$router.push({
          path: "list",
          query: {
            location: this.location,
            // capacity: this.capacity,
            min_capacity: min_capacity,
            max_capacity: max_capacity,
            start_date: this.start_date,
            headCount: this.headCount,
          },
        });
        let searchData = {
          location: this.location,
          // capacity: this.capacity,
          min_capacity: min_capacity.toString(),
          max_capacity: max_capacity.toString(),
          start_date: this.start_date,
        };
        this.getPropertyBySearchCriteria(searchData);
      }
    },
    getNearBy() {
      var elementName = document.getElementsByClassName("result__filter");
      elementName[0].classList.toggle("is-filterOpen");
    },
    getStartDate() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen) {
        testarray[0].classList.add("is-open-date");
      } else {
        testarray[0].classList.remove("is-open-date");
      }
    },
    close() {
      var testarray = document.getElementsByClassName("vdp-datepicker");
      if (!this.$refs.picker.isOpen && !this.start_date) {
        testarray[0].classList.remove("is-open-date");
      } else {
        testarray[0].classList.add("is-open-date");
      }
    },
    getSearchByLocation() {
      this.location = "Mercer Island, WA";
      this.getLocation("Mercer Island, WA");
    },
    getSearchByDate() {
      // this.searchData = true;
      this.start_date = this.dateRange;
    },
    getSearchByCapacity() {
      this.capacity = this.capacityRange;
    },

    async getPropertyBySearchCriteria(searchData) {
      searchData.capacity = null; //adding this as dummy data as api is throwing error we need to remove that when api fixes
      searchData.min_capacity =
        searchData.min_capacity === "NaN" ? null : searchData.min_capacity;
      searchData.max_capacity =
        searchData.max_capacity === "NaN" ? null : searchData.max_capacity;
      await getPropertyBySearch(searchData)
        .then((res) => {
          this.properties = (res.data && res.data.data) || [];

          this.loading = false;

          this.chunkProperties = [];

          console.log(Array.isArray(this.properties));

          if (this.properties.length && this.properties.length > 0) {
            this.searchData = true;
            this.loading = false;
            var index = 0;
            let chunk_size = 2;
            var arrayLength = this.properties.length;
            let myChunk = [];
            for (index = 0; index < arrayLength; index += chunk_size) {
              myChunk = this.properties.slice(index, index + chunk_size);
              // Do something if you want with the group
              this.chunkProperties.push(myChunk);
              console.log("chunkproperties.....", this.chunkProperties);
            }
          } else {
            this.searchData = false;
            let resp = res.data.data;
            this.dateRange = resp.dateRange;
            this.dateProperties = resp.dateProperties;
            this.capacityProperties = resp.capacityProperties;
            this.capacityRange = resp.capacityRange;
          }
          this.capacity = this.capacity;
        })
        .catch((error) => {
          console.error(error);
        });
      this.loading = false;
    },
    setLength(contLength) {
      this.contractLength = contLength;
      this.isContractLength = false;
    },
    setLayout(layout) {
      this.layoutDesign = layout;
      this.isLayoutDesign = false;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    available(date) {
      let today_date = new Date();
      let new_date = new Date(date);
      if (new_date > today_date) {
        return false;
      }
      return true;
    },
    getDetail(id) {
      id = btoa(id);
      if (!getToken()) {
        let page = "/detail/" + id;
        console.log("redirectTo", page);
        this.$router.push({
          path: "list",
          query: {
            location: this.location,
            min_capacity: this.min_capacity,
            max_capacity: this.max_capacity,
            start_date: this.start_date,
            start_date: this.start_date,
            headCount: this.headCount,
            redirectTo: page,
          },
        });
        // this.start_date = "";
        this.$root.$emit("getSignup", true);
        // alert("please login first");
      } else {
        this.$router.push("/detail/" + id);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.clickCursor {
  cursor: pointer;
}

.test .vdp-datepicker__calendar {
  width: 260px !important;
}

.simple-range-slider {
  width: 100% !important;
  background-color: white;
  padding: 0;
  margin: 0.25em;
}
.noofmaxheadcount,
.date {
  font-size: 1.4rem;
}
.layout,
.plan_paid,
.noofmaxheadcount,
.date {
  color: dimgrey;
}
.exactprice,
.contracts,
.max-headcount,
.noofmaxheadcount,
.date {
  font-weight: 500 !important;
}
.space-btwn {
  justify-content: space-between;
}
.result__content .price {
  font-family: Inter-regular;
}
.result__content > h3 {
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
@media (max-width: 1599px) {
  .price_date {
    flex-wrap: wrap;
    > div:nth-child(3) {
      margin-top: 12px;
      width: 100%;
    }
  }
}
</style>
